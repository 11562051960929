import React, { useContext, createContext, useState, useEffect } from 'react';
import { Account } from '../../domain/entities/Account';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    Account.subscribe((user) => {
      console.log('User: ', user);
      setUser(user);
      setLoading(false);
    });
  }, []);

  const login = (email, password) => {
    return Account.logIn(email, password);
  };

  const logout = () => {
    return Account.logOut();
  };

  return {
    user,
    loading,
    login,
    logout
  };
}
