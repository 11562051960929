import { AuthDataSource } from "../../data/sources/AuthDataSource";

class User {
    constructor(id, email, firstName, lastName) {
        this.id = id;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
    }
}

class AccountEntity {
    constructor () {
        this.user = null;
        this.jwt = "";

        this.subscribers = [];

        AuthDataSource.subscribe((jwt, user) => {
            console.log(user);
            this.user = user && new User(
                user.email.split('@')[0].replace('.', '-'),
                user.email,
                user.given_name,
                user.family_name
            );
            this.jwt = jwt;
            this.subscribers.map(cb => cb(user));
        })
    }

    subscribe (cb) {
        this.subscribers.push(cb);
        cb(this.user);
    }

    getCurrent () {
        return this.user;
    }

    logIn (email, password) {
        AuthDataSource.logIn(email, password);
    }

    logOut () {
        AuthDataSource.logOut()
    }
}

export const Account = new AccountEntity();