export class Logger {

    constructor (tag) {
        this.tag = `[${tag}]`;
    }

    log (...args) {
        console.log(this.tag, ...args);
    }
    warn (...args) {
        console.warn(this.tag, ...args);
    }
    error (...args) {
        console.error(this.tag, ...args);
    }
}