// Zukan
/*
export const USER_POOL_ID = 'eu-central-1_w62dXpdCp';
export const CLIENT_ID = '5hn48735j6g9jfku6c2jb7k0sb';
export const API_BASE_URL = 'https://api.zukan.link';
*/

// Coredex

export const USER_POOL_ID = 'eu-central-1_wOYPbAtpm';
export const CLIENT_ID = '7j6114hmt9592idbrr55rvf93';
export const API_BASE_URL = 'https://api.coredex.link';
