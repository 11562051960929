import React, { useState } from "react";
import {
  useNavigate,
  useLocation,
  Link
} from "react-router-dom";

import '../styles/login.scss';

import { useAuth } from "../utils/authContext";

export function LoginPage() {
    let history = useNavigate();
    let location = useLocation();
    let auth = useAuth();
  
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    
    let { from } = location.state || { from: { pathname: "/" } };
    let login = () => {
      auth.login(username, password);
    };

    let logout = () => {
      auth.logout();
    };
  
    return (
      <div className="authWrapper">
        <div className="loginContainer">
          <h1>Login</h1>
          
          <Link to="/"><button className="secondaryBtn">To the Home</button></Link>

          <div className="formLine">
            <span className="formLabel">Username:</span>
            <input 
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="formLine">
            <span className="formLabel">Password:</span>
            <input 
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="primaryBtn" onClick={login}>Log in</button>

          {auth.user ? <div>User: {auth.user.id} <br/> <button className="primaryBtn" onClick={logout}>Logout</button></div> : ''}
        </div>
      </div>
    );
  }

/*
<p>You must log in to view the page at {from.pathname}</p>
*/